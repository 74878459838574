<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-8">
              <h3 class="mb-0">
                {{ supplyRule?.name }} -
                <base-button
                  v-if="$currentUserCan($permissions.PERM_EDIT_SUPPLY_RULES)"
                  type="primary"
                  icon
                  size="sm"
                  @click="editSupplyRule()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-edit"></i>
                  </span>
                </base-button>

                <base-button
                  v-if="$currentUserCan($permissions.PERM_CREATE_SUPPLY_RULES)"
                  type="warning"
                  icon
                  size="sm"
                  @click="copySupplyRule()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-copy"></i>
                  </span>
                </base-button>

                <base-button
                  v-if="$currentUserCan($permissions.PERM_DELETE_SUPPLY_RULES)"
                  type="danger"
                  icon
                  size="sm"
                  @click="deleteSupplyRulle()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-trash"></i>
                  </span>
                </base-button>
              </h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack()"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>

              <notification-subscription
                :objectType="'supplyRules'"
                :objectId="supplyRule.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
            </div>
          </div>
        </template>
        <div>
          <div
            class="col-12 justify-content-center justify-content-sm-between flex-wrap"
          >
            <tabs
              fill
              class="flex-column flex-md-row"
              tabNavWrapperClasses="nav-wrapper"
              tabNavClasses="nav nav-pills nav-fill"
              value="global"
            >
              <card shadow>
                <tab-pane title="global" id="1" :active="true">
                  <span slot="title">
                    <i class="ni ni-cloud-upload-96" />
                    {{ $t("COMMON.GLOBAL") }}
                  </span>
                  <supply-rule-view-global
                    :supplyRule="supplyRule"
                    @supplyRuleUpdated="get"
                  />
                </tab-pane>

                <!--
                <tab-pane
                    title="global"
                    id="3"
                    :active="true"
                    v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SUPPLIERS)"
                >
                  <span slot="title">
                    <i class="ni ni-circle-08 text-primary" />
                    {{ $t("COMMON.LOCATIONS") }}
                  </span>
                  <location-list-table
                      :filterOrganization="supplyRule.organization.id"
                      :filterSupplierRuleSelected="[supplyRule]"
                  >
                  </location-list-table>
                </tab-pane>
                -->

                <tab-pane
                  title="logs"
                  id="999"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
                >
                  <span slot="title">
                    <i class="fa fa-file" />
                    {{ $t("COMMON.LOGS") }}
                  </span>
                  <supply-rule-view-logs :supplyRule="supplyRule" />
                </tab-pane>
              </card>
            </tabs>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import { cloneDeep } from "lodash";
import swal from "sweetalert2";
import { TabPane, Tabs } from "@/components";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import defaultWarehouse from "./defaultSupplyRule";
import SupplyRuleViewGlobal from "./partials/SupplyRuleViewGlobal.vue";
import SupplyRuleViewLogs from "./partials/SupplyRuleViewLogs.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    SupplyRuleViewGlobal,
    SupplyRuleViewLogs,
    NotificationSubscription,
  },

  mixins: [],

  data() {
    return {
      supplyRule: cloneDeep(defaultWarehouse),
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("supplyRules/get", { id });
        this.supplyRule = this.$store.getters["supplyRules/supplyRule"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async editSupplyRule(row) {
      this.$router.push({
        name: "Edit Supply Rule",
        params: { id: this.supplyRule.id },
      });
    },

    async copySupplyRule(row) {
      this.$router.push({
        name: "Add Supply Rule",
        query: { id: this.supplyRule.id, mode: "duplication" },
      });
    },

    async deleteSupplyRulle() {
      const confirmation = await swal.fire({
        title: this.$t("SUPPLY_RULES.DELETE_THIS_SUPPLY_RULE"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("supplyRules/destroy", this.supplyRule.id);
          await this.goBack();
          this.$notify({
            type: "success",
            message: this.$t("SUPPLY_RULES.SUPPLY_RULE_DELETED"),
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    goBack() {
      this.$router.push({ name: "List Supply Rules" });
    },
  },
};
</script>
