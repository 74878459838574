<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <h1>{{ `${supplyRule.name}` }}</h1>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
          <dd class="col-sm-8">
            {{ supplyRule.name }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("SUPPLY_RULES.SUPPLY_RULE_DELIVERY_DAYS") }}
          </dt>
          <dd class="col-sm-8">
            <span v-for="(day, key) in supplyRule.delivery_days" :key="key">
              {{ $t(`SUPPLY_RULES.SUPPLY_RULE_DELIVERY_DAY_${day}`) }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("SUPPLY_RULES.SUPPLY_RULE_ORDER_DELAY") }}
          </dt>
          <dd class="col-sm-8">
            {{ supplyRule.order_delay }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("SUPPLY_RULES.SUPPLY_RULE_ORDER_BEFORE_HOUR") }}
          </dt>
          <dd class="col-sm-8">
            {{ supplyRule.order_before_hour }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("SUPPLY_RULES.SUPPLY_RULE_MIN_QUANTITY") }}
          </dt>
          <dd class="col-sm-8">
            {{ supplyRule.min_purchase }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("SUPPLY_RULES.SUPPLY_RULE_MAX_QUANTITY") }}
          </dt>
          <dd class="col-sm-8">
            {{ supplyRule.max_purchase }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("SUPPLY_RULES.SUPPLY_RULE_ORDER_METHOD") }}
          </dt>
          <dd class="col-sm-8">
            {{
              $t(
                `SUPPLY_RULES.SUPPLY_RULE_ORDER_METHOD_${supplyRule.order_method}`
              )
            }}
          </dd>
        </dl>

        <dl class="row" v-if="supplyRule.order_method === METHOD_EMAIL">
          <dt class="col-sm-4">
            {{ $t("COMMON.EMAIL") }}
          </dt>
          <dd class="col-sm-8">
            {{ supplyRule.email }}
          </dd>
        </dl>

        <dl
          class="row"
          v-if="
            supplyRule.order_method === METHOD_SMS ||
            supplyRule.order_method === METHOD_PHONE
          "
        >
          <dt class="col-sm-4">
            {{ $t("COMMON.PHONE") }}
          </dt>
          <dd class="col-sm-8">
            <phone-number
              :extension="supplyRule.phone_extension"
              :type="supplyRule.phone_type"
              :phoneNumber="supplyRule.phone"
            />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("COMMON.OTHER_PHONES") }}
          </dt>
          <dd class="col-sm-8">
            <span v-for="(line, index) in supplyRule.other_phones" :key="index">
              <phone-number
                :phoneNumber="line.phoneNumber"
                :extension="line.extension"
                :type="line.type"
              /><br />
            </span>
          </dd>
        </dl>

        <template v-if="supplyRule.order_method === METHOD_ONLINE_ORDER">
          <dl class="row" v-if="supplyRule.website">
            <dt class="col-sm-4">
              {{ $t("SUPPLY_RULES.WEBSITE_URL") }}
            </dt>
            <dd class="col-sm-8">
              <a :href="supplyRule.website" target="_blank" rel="noreferrer">
                {{ supplyRule.website }}
              </a>
            </dd>
          </dl>
        </template>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("COMMON.CUSTOMER_NUMBER") }}
          </dt>
          <dd class="col-sm-8">
            {{ supplyRule.customer_number }}
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="supplyRule.organization" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SUPPLIERS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.SUPPLIERS") }}</dt>
          <dd class="col-sm-8" v-if="supplyRule.supplier">
            <supplier :supplier="supplyRule.supplier" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_WAREHOUSES)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.WAREHOUSES") }}</dt>
          <dd class="col-sm-8" v-if="supplyRule.warehouse">
            <warehouse :warehouse="supplyRule.warehouse" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd class="col-sm-8">
            <locations :locations="supplyRule.allowedLocations" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(supplyRule.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(supplyRule.updated_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("SUPPLY_RULES.EXCERPT") }}
          </dt>
          <dd class="col-sm-8">
            <div v-html="supplyRule.excerpt"></div>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import {
  METHOD_EMAIL,
  METHOD_ONLINE_ORDER,
  METHOD_SMS,
  METHOD_PHONE,
} from "@/constants/supplyRules";

export default {
  name: "supply-rule-view-global",

  props: ["supplyRule"],

  components: {},

  data() {
    return {
      METHOD_ONLINE_ORDER,
      METHOD_EMAIL,
      METHOD_PHONE,
      METHOD_SMS,
    };
  },

  computed: {},

  created() {},

  methods: {
    supplyRuleUpdated() {
      this.$emit("supplyRuleUpdated", true);
    },
  },

  mounted() {},

  watch: {
    supplyRule(supplyRule) {},
  },
};
</script>
